(function($){
    $(function(){
  
      $('.sidenav').sidenav();
      $('.carousel').carousel(
        {
          dist: 0,
          padding: 0,
          fullWidth: true,
          indicators: true,
          duration: 100,
        }
        );
        autoplay()   
        function autoplay() {
            $('.carousel').carousel('next');
            setTimeout(autoplay, 10000);
        }
    }); // end of document ready
  })(jQuery); // end of jQuery name space

  function goBack() {
    window.history.back();
  }